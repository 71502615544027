import React, { type FC, useEffect, useRef, useState, useLayoutEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';

import { useAppSelector } from '../../hooks/redux';
import { useWindowSize } from '../../hooks/useWindowSize';
import { selectIsAlertMessage, selectAlertMessage } from '../../redux/selectors/alertMessage';

import styles from './RunningTickerComponent.module.css';

enum RunningLineElements {
  iconWidth = 48,
  averageCharWidth = 0.56,
  fullScreenFontSize = 14,
  mobileScreenFontSize = 12,
  gap = 40,
}

interface AlertMessageData {
  brandId: string[]
  value: string
  variant: 'default' | 'warning' | 'error'
}

type MatchArray = AlertMessageData[];

const massageConditionStyles = {
  default: {
    backgroundColor: 'var(--CFD-theme-Custom-Purple)',
    color: 'var(--CFD-theme-Custom-OnRurple)',
    iconComponent: <LocalOfferRoundedIcon sx={{ color: 'var(--CFD-theme-Custom-OnRurple)' }} />,
    iconBackGround: 'var(--CFD-theme-State-layers-on-primary-opacity_08)'
  },
  warning: {
    backgroundColor: 'var(--CFD-theme-System-Inversesurface)',
    color: 'var(--CFD-theme-System-Inverseonsurface)',
    iconComponent: <WarningIcon sx={{ color: 'var(--CFD-theme-System-Inverseonsurface)' }} />,
    iconBackGround: 'var(--CFD-theme-State-layers-inverse-on-surface-opacity_08)'
  },
  error: {
    backgroundColor: 'var(--CFD-theme-System-Error)',
    color: 'var(--CFD-theme-System-OnError)',
    iconComponent: <ErrorIcon sx={{ color: 'var(--CFD-theme-System-OnError)' }} />,
    iconBackGround: 'var(--CFD-theme-State-layers-on-error-opacity_08)'
  }
}

const runningLineWidthCalculation = (screenWidth: number, messageLength: number): number => {
  const isMobile = screenWidth <= 600;
  const textSize = isMobile
    ? (RunningLineElements.mobileScreenFontSize * RunningLineElements.averageCharWidth)
    : (RunningLineElements.fullScreenFontSize * RunningLineElements.averageCharWidth);
  const runningLineWidth = isMobile
    ? ((textSize * messageLength) - RunningLineElements.iconWidth) + RunningLineElements.gap
    : (textSize * messageLength) - RunningLineElements.iconWidth
  const compareScreenWidth = screenWidth - RunningLineElements.iconWidth;
  const resultWidth = compareScreenWidth > runningLineWidth ? (screenWidth) : (runningLineWidth);
  return resultWidth;
}

function determineAlertMessage (matchArrays: MatchArray): AlertMessageData | null {
  let selectedMessage = null;
  const allBrandsMessageExist = 2;
  const oneBrandMessageExist = 1;

  if (matchArrays.length === allBrandsMessageExist) {
    const singleBrandIdMessage = matchArrays.find(data => data.brandId.length === 1);
    const multipleBrandIdMessage = matchArrays.find(data => data.brandId.length > 1);

    if (singleBrandIdMessage?.value) {
      selectedMessage = singleBrandIdMessage;
    } else if (multipleBrandIdMessage?.value) {
      selectedMessage = multipleBrandIdMessage;
    }
  } else if (matchArrays.length === oneBrandMessageExist) {
    selectedMessage = matchArrays[0];
  }

  return selectedMessage;
}

export const RunningTickerComponent: FC = () => {
  const [screenWidth] = useWindowSize();
  const { brandId: userBrandId } = useAppSelector((state) => state.user);
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const alertMessageData = useAppSelector(selectAlertMessage);
  const [runningLineWidth, setRunningLineWidth] = useState<number>(0);
  const [animationKey, setAnimationKey] = useState<number>(0);
  const [alertMessageText, setAlertMessageText] = useState<string>('');
  const [tickerVariant, setTickerVariant] = useState<'default' | 'warning' | 'error'>('default');
  const tickerRef = useRef<HTMLDivElement>(null);
  const tickerStyles = massageConditionStyles[tickerVariant];

  const speedOfTicker = 25;

  useLayoutEffect(() => {
    if (isAlertMessage) {
      const userBrandIdStr = userBrandId ?? '';
      const matchArrays = alertMessageData.filter(data => data.brandId.includes(userBrandIdStr));

      const selectedMessage = determineAlertMessage(matchArrays as AlertMessageData[]);

      if (selectedMessage !== null) {
        setAlertMessageText(selectedMessage.value ?? '');
        setTickerVariant(selectedMessage.variant ?? 'default');
      }
      const calculateWidth = runningLineWidthCalculation(screenWidth, alertMessageText.length);
      setRunningLineWidth(calculateWidth)
    }
  }, [alertMessageData, userBrandId, isAlertMessage, screenWidth, alertMessageText]);

  useEffect(() => {
    setAnimationKey(prevKey => prevKey + 1);
  }, []);

  return (
    <div className={styles.tickerContainer} style={{ backgroundColor: tickerStyles.backgroundColor }}>
      <div className={styles.iconContainer} style={{ backgroundColor: tickerStyles.backgroundColor }}>
        <div className={styles.icon} style={{ backgroundColor: tickerStyles.iconBackGround }}>
          {tickerStyles.iconComponent}
        </div>
      </div>
      <div
        key={animationKey}
        className={styles.tickerWrapper}
        style={{
          animationDuration: `${speedOfTicker}s`,
          width: `${runningLineWidth === 0 ? 0 : runningLineWidth}px`
        }}
        ref={tickerRef}
      >
        <div className={styles.tickerMessage} style={{ color: tickerStyles.color }}>
          {alertMessageText}
        </div>
        <div className={styles.tickerMessage} style={{ color: tickerStyles.color }}>
          {alertMessageText}
        </div>
      </div>
    </div>
  );
}
